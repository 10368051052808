import React, { createRef } from 'react';
import styled from 'styled-components';

import { devices } from '../../constants';
import { mapRange } from '../../utils';

interface ImageBoxProps {
    top: number;
    left: number;
    ref: React.RefObject<HTMLImageElement>,
    width: number;
    height: number;
    src: any;
    highResImageLoaded: boolean;
}

const ImageBox = styled.img.attrs<ImageBoxProps>(props => ({
    style: {
        height: props.height + 'vh',
        transform: `translate(${props.left}px, ${props.top}px)`,
    }
}))<ImageBoxProps>`
    object-fit: contain;
    min-height: 400px;
    outline-style: none;
    transition: transform 1s ease-out, opacity 0.6s ease-in, filter 1s ease-in;
    position: relative;
    opacity: 0.8;
    z-index: 10;
    @media ${devices.mobileS} {
        width: 100vw;
    }
    @media ${devices.tablet} {
        width: 80vw;
    }
    @media ${devices.laptopL} {
        width: ${({width}) => width}vw;
        height: ${({height}) => height}vh;
        min-width: 325px;
    }
    &:hover {
        opacity: 1;
        z-index: 998 !important;
        cursor: pointer;
    }
    filter: ${({highResImageLoaded}) => !highResImageLoaded ? 'blur(10px)' : 'blur(0px)'};
`;


const ImageContainer = styled.div`
    min-height: 80vh;
    @media ${devices.mobileS} {
        width: 100vw;
    }
    @media ${devices.mobileL} {
        width: 80vw;
    }
    @media ${devices.laptopL} {
        min-height: 100vh;
        min-width: calc(600px + 11vw);
        width: 43vw;
    }
`;


export interface ContentImageProps {
    seed: number,
    src: any,
    progressive: any,
    scrollTop: number,
    loadHighRes: boolean,
    onClick: any,
}

const randomY = [.1,.2,.4,.4,.4,.4,.4,.4]
const randomLeft = [-.1,-.08,-.06,-.05,-.03,-.01,.01,0.03,.06,0.08,0.1];
const randomLeftMobile = [0.1, 0.03, 0.06, 0.08, 0.1, 0.2, 0.2]

class ContentImage extends React.Component<ContentImageProps> {
    state: {
        top: number,
        left: number,
        width: number,
        height: number,
        highResImageLoaded: boolean,
    }
    private ref = createRef<HTMLImageElement>();
    constructor(props: ContentImageProps) {
        super(props);
        const topMult = randomY[Math.floor(Math.random() * 10)];
        this.state = {
            top: props.seed === 0 ? window.innerHeight/5 : Math.abs(Math.sin(props.seed)) * window.innerHeight * topMult,
            // left: randomLeft[Math.floor(Math.random() * 10)] * window.innerWidth,
            left: 0,
            width: 40,
            height: 90,
            highResImageLoaded: false,
        }
    }
    componentDidMount() {
        const rect = this.ref.current?.getBoundingClientRect();
        this.recalculateLeft(rect, true);
        window.addEventListener('resize', () => {
            const rect = this.ref.current?.getBoundingClientRect();
            this.recalculateLeft(rect, true);
        });
    }
    componentDidUpdate(prevProps: any) {
        if (this.props.loadHighRes && !prevProps.loadHighRes) {
            new Promise((resolve, reject) => {
                const image = new Image();
                image.src = this.props.src;
                image.onload = () => {
                    resolve('');
                }
            }).then(() => {
                this.setState({
                    highResImageLoaded: true,
                });
            })
        }
    }
    recalculateLeft(rect: DOMRect | undefined, force: boolean): void {
        if (!rect) return;
        if (window.screen.width < 768) {
            this.setState({
                top: 0,
            })
            return;
        }
        if (window.screen.width < 1920) {
            this.setState({
                left: randomLeftMobile[Math.floor(Math.random() * 6)] * window.innerWidth,
            });
            return;
        }
        if (rect.left >= window.innerWidth/2) {
            if (this.state.left > window.innerWidth/2 + rect.width || force) {
                //recalculate to move it left
                this.setState({
                    left: randomLeft[Math.floor(Math.random() * 6)] * window.innerWidth,
                });
            }
        } else if (rect.left < 0 || force) {
            //recalculate to move it right
            this.setState({
                left: randomLeft[Math.floor(Math.random() * 5) + 6] * window.innerWidth,
            });
        }
    }
    calculateParallax():number {
        const { scrollTop, seed } = this.props;
        const rect = this.ref.current?.getBoundingClientRect();
        let offsetY = 0;
        if (!rect) return offsetY;
        if (rect.top + rect.height > 0 && rect.top < window.innerHeight) {
            offsetY = mapRange(
                rect.top + rect.height, 
                0,
                window.innerHeight,
                0,
                window.innerHeight/5
            );
        } else if (rect.top < 0) {
            offsetY = window.innerHeight/5;
        } else {
            offsetY = 0;
        }
        return -1*offsetY;
    }
    render() {
        const { top, left, width, height } = this.state;
        const { src, progressive } = this.props;
        const offsetY: number = this.calculateParallax();
        return (
            <ImageContainer>
                <ImageBox 
                    ref={this.ref} 
                    top={top + offsetY} 
                    left={left} 
                    width={width} 
                    height={height} 
                    onClick={this.props.onClick}
                    src={this.state.highResImageLoaded ? src : progressive} 
                    highResImageLoaded={this.state.highResImageLoaded}
                    />
            </ImageContainer>
        )
    }
}

export default ContentImage