import React from 'react';
import styled from 'styled-components';

import { devices } from '../../constants';
import { photos } from '../../assets';

const ImageContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @media ${devices.mobileS} {
        height: calc(100vh - 200px);
    }
    @media ${devices.laptopL} {
        height: 100%;
    }
`;

interface ImageProps {
}
const Image = styled.img<ImageProps>`
    max-height: 100%;
    max-width: 100%;
`;

interface ImageModalProps {
    imageNum: number;
}
const ImageModal: React.FC<ImageModalProps> = (props) => {
    return (
        <ImageContainer>
            <Image src={photos[props.imageNum]} />
        </ImageContainer>
    );
}

export default ImageModal;
