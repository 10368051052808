import React from 'react';
import styled from 'styled-components';

import { devices } from '../../constants';

const TextAreaContainer = styled.div`
    width: 100%;
    height: 100%;
    @media ${devices.mobileS} {
        height: calc(100vh - 101px);
        margin: 0 30px;
    }
    @media ${devices.tablet} {
        margin: 0 100px;
    }
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
`;

interface TextProps {
    underline?: boolean
}
const Text = styled.p<TextProps>`
    font-family: Rubik, HelveticaNeue-Light;
    font-size: 18px;
    font-weight: 300;
    text-decoration: ${({underline}) => underline ? 'underline' : ''};
`;

const Padding = styled.div`
    width: 100%;
    @media ${devices.mobileS} {
        height: 60px;
        min-height: 60px;
    }
    @media ${devices.laptopL} {
        height: 160px;
        min-height: 160px;
    }
`

interface TextModalProps {
    fontLoaded: boolean;
}

const TextModal: React.FC<TextModalProps> = (props) => {
    return (
        <TextAreaContainer>
            <Padding />
            <Text>
                LOH is an international fashion brand based in T'bilisi. Founded in 2016 by Yuliya Darkovich. 
                
                <br/>
                <br/>
                All items are unique; pulled from a dream to be made real the moment
                you put them on.
            </Text>
                <br/>
                <br/>
            <Text>
                For all general inquiries please contact inquiries at lohautecouture dot com                           
                <br/>
                <br/>
                For all press inquiries please contact press at lohautecouture dot com
            </Text>
                <br/>
                <br/>
            <Text>
                Copyright 2021 LOH
            </Text>
            <Padding />
        </TextAreaContainer>

    )
}

export default TextModal;
