import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group'; // ES6
import styled from 'styled-components';

import { devices } from '../../constants';

import TextModal from './TextModal';
import ImageModal from './ImageModal';

interface ModalAreaProps {
    show: boolean;
}
const ModalArea = styled.div<ModalAreaProps>`
    background: rgba(255,255,255,0.68);
    backdrop-filter: blur(10px);
    position: fixed;
    pointer-events: ${({show}) => show ? 'auto' : 'none'};
    transition: opacity 0.6s ease-out;
    display: flex;
    animation-delay: 0.2s;
    z-index: 999;
    top: 0;
    @media ${devices.mobileS} {
        height: calc(100vh);
        width: 100vw;
        left: 0;
    }
    @media ${devices.laptopL} {
        height: 100vh;
        width: calc(100vw - 173px);
        left: 173px;
    }
    &.modal-transition-enter {
        opacity: 0;
    }
    &.modal-transition-enter-active {
        opacity: 1;
        transition: opacity 600ms ease-out; 
    }
    &.modal-transition-exit {
        opacity: 1;
    }
    &.modal-transition-exit-active {
        opacity: 0;
        transition: opacity 600ms ease-out;
    }
`

interface FixedModalAreaProps {
    show: boolean;
    showImage: number | null;
    fontLoaded: boolean;
}

const FixedModalArea: React.FC<FixedModalAreaProps> = (props) => {
    return  (
        <CSSTransition in={props.show || props.showImage !== null} timeout={600} classNames="modal-transition" unmountOnExit={true}>
                <ModalArea show={props.show || props.showImage !== null}>
                    { props.showImage !== null && (
                        <ImageModal imageNum={props.showImage} />
                    )}
                    { props.show && (
                        <TextModal fontLoaded={props.fontLoaded} />
                    )}
                </ModalArea> 
        </CSSTransition>
    );
}

export default FixedModalArea;