import { createGlobalStyle } from "styled-components"
import SpaceGrotesk from './fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.ttf';
import SpaceGroteskVariable from './fonts/SpaceGrotesk/SpaceGrotesk-VariableFont_wght.ttf';
import Rubik from './fonts/Rubik/Rubik-Light.ttf';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline:0;
        font-family: SpaceGroteskVariable;
    }
    #root{
        margin:0 auto;
        width: 100vw;
    }
    html, body {
        max-width: 100%;
        overflow-x: hidden;
        overflow-scrolling: auto;
    }
    @font-face {
        font-family: "SpaceGrotesk";
        font-style: normal;
        font-weight: 600;
        src: url(${SpaceGrotesk});
        font-display: block;
    }
    @font-face {
        font-family: "SpaceGroteskVariable";
        src: url(${SpaceGroteskVariable});
        font-display: block;
    }
    @font-face {
        font-family: "Rubik";
        src: url(${Rubik});
        font-display: block;
    }
`