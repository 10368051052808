import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components'

import { devices } from '../../constants';

import loh_logo from '../../loh_logo.svg';
import loh_mini from '../../LOH_mini.svg';
import dots from '../../dots_organic.svg';
import crosshatch from '../../crosshatch.svg';

const LogoContainer = styled.div`
    flex: 1;
    padding-top: 33px;
    @media ${devices.mobileS} {
        transform: translate(22px, -19px);
    }
    @media ${devices.laptopL} {
        transform: translate(0,0);
    }
`;
const Logo = styled.img`
    height: auto;
    width: 79px;
    @media ${devices.mobileS} {
        transform: translate(0px, -9px);
    }
    @media ${devices.laptopL} {
        transform: translate(-5px, 0);
    }
`;

const LohTextLogo = styled.div`
    @media ${devices.mobileS} {
        margin-top: 9px;
        transform: translate(0px, -9px);
    }
    @media ${devices.laptopL} {
        margin-top: 19px;
    }
`;

const LohText = styled.img`
    width: 79px;
    height: 29px;
    position: relative;
    top: -29px;
`

const LohBlur = styled.div`
    background: #FFC8ED;
    filter: blur(8px);
    width: 79px;
    height: 29px;
`

const DotsContainer = styled.div`
    display: flex;
    flex: 1;
    @media ${devices.mobileS} {
        flex-direction: row;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
    }
    @media ${devices.mobileL} {
        justify-content: flex-start;
    }
    @media ${devices.laptopL} {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;
const ButtonArea = styled.div`
    display: flex;
    @media ${devices.mobileS} {
        width: 101px;
        height: 101px;
        margin-left: -50.5px;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg);
    }
    @media ${devices.laptopL} {
        margin-left: 0; 
        width: 173px;
        max-height: 173px;
        flex: 1;
        align-items: center;
        justify-content: center;
        transform: rotate(0deg) translate(0, -61px);
    }
    &:hover {
        cursor: pointer;
    }
    &.dots-enter {
        opacity: 0;
    }
    &.dots-enter-active {
        opacity: 1;
        transition: opacity 600ms ease-out; 
    }
    &.dots-exit {
        opacity: 1;
    }
    &.dots-exit-active {
        opacity: 0;
        transition: opacity 600ms ease-out;
    }
`;
interface ButtonProps {
    show: boolean;
}
const Dots = styled.img<ButtonProps>`
    width: 9px;
    height: 51px;
    opacity: ${({show}) => show ? 1 : 0};
    transition: opacity 300ms ease-in;
    transition-delay: ${({show}) => show ? 300 : 0}ms;
`;

const CrossHatch = styled.img<ButtonProps>`
    width: 38px;
    height: 38px;
    position: absolute;
    opacity: ${({show}) => show ? 1 : 0};
    transition: opacity 300ms ease-in;
    transition-delay: ${({show}) => show ? 300 : 0}ms;
`;

interface FixedBarProps {
    scrollTop: number;
    hideBg: boolean;
}
        // background: rgba(255,255,255,${({hideBg}) => hideBg ? 0 : 0.68});
const FixedBar = styled.div.attrs<FixedBarProps>(props => ({
    style: {
        background: props.hideBg ? 'rgba(255,255,255,0)' : 'rgba(255,255,255,0.68)',
        backdropFilter: props.hideBg ? '' : 'blur(10px)',
    }
}))<FixedBarProps>`
    @media ${devices.mobileS} {
        flex-direction: row;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 101px;
    }
    @media ${devices.laptopL} {
        flex-direction: column;
        left: 0;
        top: 0;
        width: 173px;
        height: 100vh;
    }
    position: fixed;
    transition: opacity 1s ease-in, backdrop-filter 1s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    pointer-events: ${({scrollTop}) => scrollTop > window.innerHeight/2 ? 'auto' : 'none'};
    opacity: ${({scrollTop}) => scrollTop > window.innerHeight/2 ? 1 : 0}
`

interface MenuBarProps {
    scrollTop: number;
    onButtonPress: () => any;
    hideBg: boolean;
}

class MenuBar extends React.Component<MenuBarProps> {
    render() {
        return (
            <FixedBar scrollTop={this.props.scrollTop} hideBg={this.props.hideBg}>
                <LogoContainer>
                    <Logo src={loh_logo}/>
                    <LohTextLogo>
                        <LohBlur/>
                        <LohText src={loh_mini}/>
                    </LohTextLogo>
                </LogoContainer>
                <DotsContainer>
                    <ButtonArea onClick={this.props.onButtonPress}>
                        <Dots src={dots} show={!this.props.hideBg} />
                        <CrossHatch src={crosshatch} show={this.props.hideBg} />
                    </ButtonArea>
                </DotsContainer>
            </FixedBar>
        );
    }
}

                        // <CSSTransition key={2} in={this.props.hideBg} timeout={600} classNames="dots" unmountOnExit={true}></CSSTransition>
export default MenuBar