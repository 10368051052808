/**
 * TODO IMPROVE
 * 
 * Give photos a background and pre-determined size?
 *  - progressive rendering
 * For menu, don't show font until the font is loaded?? or load the font immediately
 * Give video a solid color backgroudn too for when image is loading?
 */

import React, { createRef } from 'react';
import styled from 'styled-components';

import LOH from '../components/Landing/LOH'; 
import CloudBG from '../components/Landing/CloudBground';
import Gallery from '../components/Gallery';
import MenuBar from '../components/MenuBar';
import FixedModalArea from '../components/FixedModalArea';
import ImageModal from '../components/FixedModalArea/ImageModal';
import TextModal from '../components/FixedModalArea/TextModal';
import { Helmet } from 'react-helmet';
import Rubik from '../fonts/Rubik/Rubik-Light.ttf';

const LandingContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 130vh;
`

const DropsContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: -0.5vw;
  left: 0;
`;

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100vh;
`

export interface AppProps {

}
class App extends React.Component<AppProps> {
  state: {
    scrollTop: number,
    showFixedModalArea: boolean,
    showImage: number | null,
    fontLoaded: boolean,
  }
  private ref = createRef<HTMLDivElement>();
  constructor(props: any) {
      super(props);
      this.state = {
          scrollTop: 0,
          showFixedModalArea: false,
          showImage: null,
          fontLoaded: false,
      }
  }
  componentDidMount() {
    new FontFace('Rubik', `url(${Rubik})`).load().then(() => {
      this.setState({
        fontLoaded: true,
      })
    })
  }
  toggleFixedModalArea = () => {
    if (this.state.showImage !== null) {
      this.setState({
        showImage: null,
      });
    } else {
      this.setState({
        showFixedModalArea: !this.state.showFixedModalArea,
      });
    }
  }
  onSelectImage = (i: number) => {
    this.setState({ 
      showImage: i,
    });
  }
  scrollHandler = () => {
    this.setState({
      scrollTop: this.ref?.current?.scrollTop,
    })
  }
  render() {
    return (
      <AppContainer ref={this.ref} onScroll={this.scrollHandler}>
        <Helmet>
          <link rel="preload" href={process.env.PUBLIC_URL + "/fonts/Rubik/Rubik-Light.tff"} as="font" type="font/ttf" />
        </Helmet>
        <LandingContainer>
          <CloudBG scrollTop={this.state.scrollTop} />
          <LOH scrollTop={this.state.scrollTop}/>
        </LandingContainer>
        <Gallery scrollTop={this.state.scrollTop} onSelectImage={this.onSelectImage}/>
        <MenuBar 
          scrollTop={this.state.scrollTop} 
          onButtonPress={this.toggleFixedModalArea} 
          hideBg={this.state.showFixedModalArea || this.state.showImage !== null} />
        <FixedModalArea fontLoaded={this.state.fontLoaded} show={this.state.showFixedModalArea} showImage={this.state.showImage}>
          
        </FixedModalArea>
      </AppContainer>
    );
  }
}

export default App;
