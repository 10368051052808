import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import ContentImage from './ContentImage';

import { thumbnails, mini_thumbnails } from '../../assets';
import { devices } from '../../constants';

interface ContainerProps {
    transition: boolean
}
const Container = styled.div<ContainerProps>`
    width: 100vw;
    position: relative;
    top: -40vh;
    display: flex;
    overflow: hidden;
    padding-top: 30vh;
    flex-direction: row;
    flex-wrap: wrap;
    @media ${devices.mobileS} {
        left: 0;
        top: -20vh;
    }
    @media ${devices.laptopL} {
        left: 12.5vw;
    }
`;

export interface GalleryProps {
    scrollTop: number;
    onSelectImage: (i: number) => any;
}
class Gallery extends React.Component<GalleryProps> {
    state: {
        transition: boolean,
        loadHighRes: boolean,
    }
    constructor(props: any) {
        super(props);
        this.state = {
            transition: false,
            loadHighRes: false,
        }
    }
    componentDidMount() {
        Promise.all(
            mini_thumbnails.map((src: any) => {
                return new Promise((resolve, reject) => {
                    const image = new Image();
                    image.src = src;
                    image.onload = () => {
                        resolve(src);
                    }
                });
            })
        ).then(() => {
            this.setState({
                loadHighRes: true,
            });
        })
    }
    componentDidUpdate() {
        if (!this.state.transition && this.props.scrollTop > 0) {
            this.setState({
                transition: true
            });
        }
    }
    renderImages = () => {
        var i: number;
        const images = [];
        for (i = 0; i < 32; i++) {
            images.push(
                <ContentImage 
                    key={i} 
                    seed={i} 
                    src={thumbnails[i]} 
                    progressive={mini_thumbnails[i]}
                    loadHighRes={this.state.loadHighRes}
                    scrollTop={this.props.scrollTop} 
                    onClick={this.props.onSelectImage.bind(null, i)}
                    />
            )
        }
        return images;
    }
    render() {
        return (
            <Container transition={this.state.transition} >
                {this.renderImages()}
            </Container>
        );
    }
}

export default Gallery;