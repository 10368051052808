const prefix = `${process.env.PUBLIC_URL}`;

// export const thumbnail_sizes = [
//     { width: 819, height: 1024 }, //1 
//     { width: 819, height: 1024 }, //2
//     { width: 1024, height: 1024 }, //3
//     { width: 768, height: 1024 }, //4 
//     { width: 819, height: 1024 }, //5
//     { width: 819, height: 1024 }, //6
//     { width: 819, height: 1024 }, //7
//     { width: 819, height: 1024 }, //8
//     { width: 819, height: 1024 }, //9
//     { width: 819, height: 1024 }, //10
//     { width: 819, height: 1024 }, //11
//     { width: 819, height: 1024 }, //12
//     { width: 819, height: 1024 }, //13
//     { width: 819, height: 1024 }, //14
//     { width: 819, height: 1024 }, //15
//     { width: 1024, height: 1024 }, //16
//     { width: 819, height: 1024 }, //17
//     { width: 819, height: 1024 }, //18
//     { width: 819, height: 1024 }, //19
//     { width: 819, height: 1024 }, //20
//     { width: 819, height: 1024 }, //21
//     { width: 819, height: 1024 }, //22
//     { width: 768, height: 1024 }, //23
//     { width: 768, height: 1024 }, //24
//     { width: 1024, height: 1024 }, //25
//     { width: 768, height: 1024 }, //26
//     { width: 768, height: 1024 }, //27
//     { width: 768, height: 1024 }, //28
//     { width: 768, height: 1024 }, //29
//     { width: 1024, height: 768 }, //30
//     { width: 1024, height: 768 }, //31
//     { width: 1024, height: 768 }, //32
// ]

export const mini_thumbnails = Array.from(Array(32)).map((_, i) => {
    return `${prefix}/photos/thumbnails/${i + 1}.jpg`;
});

export const thumbnails = Array.from(Array(32)).map((_, i) => {
    return `${prefix}/photos/${i + 1}.jpg`;
});
export const photos = Array.from(Array(32)).map((_, i) => {
    return `${prefix}/photos/large/${i+1}.jpg`;
});

export const video = {
    cover: `${prefix}/videos/cover.jpg`,
    source: `${prefix}/videos/Water.mp4`
}

