import React, { createRef } from 'react';
import { default as styled, keyframes, css } from "styled-components";
import { devices } from '../../constants';
const breatheSVG = keyframes`
    0% {
        stroke-width: 1;
    }
    50% {
        stroke-width: 5;
    }
    100% {
        stroke-width: 1;
    }
` 

const fade = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.87;
    }
    100% {
        opacity: 1;
    }
`;

interface ContainerProps {
}
export const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    top: 0;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 1;
    position: absolute;
`;

interface StyledLOHSVGProps {
    ref: any,
}
const StyledLOHSVG = styled.svg<StyledLOHSVGProps>`
    z-index: -1;
    @media ${devices.mobileS} {
        width: 100vw;
        min-height: 37.5vw;
    }
    @media ${devices.tablet} {
        width: 80vw;
        min-height: 30vw;
    }

    @media ${devices.laptop} {
    }
`

const StyledLOHPath = styled.path`
    stroke: #FFECFD;
    stroke-width: 1;
    animation: ${breatheSVG} 5s ease-in infinite, ${fade} 5s ease-in infinite;
`

export interface LOHProps {
    scrollTop: number;
}

const Padding = styled.div`
    min-height: 100vh;
    width: 200px;
`

class LOH extends React.Component<LOHProps> {
    private ref = createRef<HTMLOrSVGImageElement>();
    state: {
        disappear: boolean,
        scrolled: boolean
    }
    constructor(props: any) {
        super(props);
        this.state = {
            disappear: false,
            scrolled: false,
        };
    }
    scrollHandler = () => {
    }
    render() {
        return (
            <Container >
                <StyledLOHSVG ref={this.ref} width="740" height="402" viewBox="0 0 740 402" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern id="img1" patternUnits="userSpaceOnUse" width="100" height="100">
                            <image href="wall.jpg" x="0" y="0" width="100" height="100" />
                        </pattern>
                    </defs>
                    <g>
                        <StyledLOHPath d="M99.3203 299V103H133.2V268.48H225.04V299H99.3203Z" fill="#FFECFD"/>
                        <StyledLOHPath d="M350.629 302.92C326.362 302.92 306.949 296.2 292.389 282.76C278.016 269.133 270.829 249.627 270.829 224.24V177.76C270.829 152.373 278.016 132.96 292.389 119.52C306.949 105.893 326.362 99.0801 350.629 99.0801C375.082 99.0801 394.496 105.893 408.869 119.52C423.242 132.96 430.429 152.373 430.429 177.76V224.24C430.429 249.627 423.242 269.133 408.869 282.76C394.496 296.2 375.082 302.92 350.629 302.92ZM350.629 272.68C365.189 272.68 376.482 268.48 384.509 260.08C392.722 251.493 396.829 239.92 396.829 225.36V176.64C396.829 162.08 392.722 150.6 384.509 142.2C376.482 133.613 365.189 129.32 350.629 129.32C336.256 129.32 324.962 133.613 316.749 142.2C308.722 150.6 304.709 162.08 304.709 176.64V225.36C304.709 239.92 308.722 251.493 316.749 260.08C324.962 268.48 336.256 272.68 350.629 272.68Z" fill="#FFECFD"/>
                        <StyledLOHPath d="M495.061 299V103H528.941V185.32H606.221V103H640.101V299H606.221V216.12H528.941V299H495.061Z" fill="#FFECFD"/>
                    </g>
                </StyledLOHSVG>
            </Container>
        )
    }
}


export default LOH;