import React from 'react';
import { default as styled, keyframes, css } from 'styled-components';
import { video } from '../../assets';

interface ContainerProps {
}
export const Container = styled.div<ContainerProps>`
    width: 100vw;
    min-height: 130vh;
    min-width: 100vw;
    background: linear-gradient(180deg, rgba(255,170,245,1) 80%, rgba(255,255,255,0));
`;

export const VideoFader = styled.div`
    width: 100vw;
    min-height: 130vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 30%);
`



export interface ImageProps {
    left: number;
    top: number;
    color: string;
    move: string | any;
    radius: string;
    size: number;
    animate: boolean;
}


interface VideoProps {
    loading: boolean,
}
const Video = styled.video<VideoProps>`
    width: 100vw;
    height: 130vh;
    position: relative;
    object-fit: fill;
    opacity: ${({ loading }) => loading ? 0 : 1};
    transition: opacity 1s ease-in;
`

export interface CloudVideoProps {
    scrollTop: number;
}
class CloudVideo extends React.Component<CloudVideoProps> {
    state: {
        loading: boolean,
    }
    constructor(props: CloudVideoProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }
    onVideoLoad = () => {
        this.setState({
            loading: false, 
        })
    }
    render () {
        return (
            <Container>
                <Video loading={this.state.loading} onLoadedData={this.onVideoLoad} src={video.source} poster="data:image/gif,AAAA" autoPlay={this.props.scrollTop <= window.innerHeight} playsInline={true} muted={true} loop={true} />
                <VideoFader />
            </Container>
        );
    }
}

export default CloudVideo;